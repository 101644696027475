/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CalculationsSettings = {
    base_currency?: CalculationsSettings.base_currency;
};
export namespace CalculationsSettings {
    export enum base_currency {
        USD = 'USD',
        EUR = 'EUR',
        SEK = 'SEK',
        BRL = 'BRL',
        GBP = 'GBP',
        GBX = 'GBX',
    }
}

