export default defineNuxtPlugin(nuxtApp => {
  const loggers = {};

  const addLogger = (cb, key) => {
    if (typeof cb === 'function') {
      loggers[key] = cb;
    }
  }

  const removeLogger = (key) => {
    delete loggers[key];
  }

  const log = (...args) => {
    Object.values(loggers).forEach(logger => {
      logger('log', ...args);
    });
  }

  const logError = (...args) => {
    Object.values(loggers).forEach(logger => {
      logger('error', ...args);
    });
  }

  const logInfo = (...args) => {
    Object.values(loggers).forEach(logger => {
      logger('info', ...args);
    });
  }

  const logWarn = (...args) => {
    Object.values(loggers).forEach(logger => {
      logger('warn', ...args);
    });
  }

  return {
    provide: {
      addLogger,
      removeLogger,
      log,
      logInfo,
      logWarn,
      logError,
    },
  };
});
