import { defineAsyncComponent } from 'vue'
export default {
  "advisor-app": defineAsyncComponent(() => import("/app/layouts/advisor-app.vue")),
  default: defineAsyncComponent(() => import("/app/layouts/default.vue")),
  "developer-menu": defineAsyncComponent(() => import("/app/layouts/developer-menu.vue")),
  embed: defineAsyncComponent(() => import("/app/layouts/embed.vue")),
  "my-benefit-demo": defineAsyncComponent(() => import("/app/layouts/my-benefit-demo.vue")),
  print: defineAsyncComponent(() => import("/app/layouts/print.vue")),
  "seb-poc": defineAsyncComponent(() => import("/app/layouts/seb-poc.vue")),
  simple: defineAsyncComponent(() => import("/app/layouts/simple.vue"))
}