/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Asset } from './Asset';
import type { BaseAssetListFilter } from './BaseAssetListFilter';
export type njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema = {
    asset_list_filters?: Array<BaseAssetListFilter>;
    assets?: Array<Asset>;
    name?: string;
    type?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema.type;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema {
    export enum type {
        PORTFOLIO_BUILDER = 'portfolio_builder',
        ASSET_CLASS = 'asset_class',
    }
}

