/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterItem } from './FilterItem';
export type njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema = {
    columns: Array<string>;
    filters?: Array<FilterItem>;
    grouping: njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema.grouping;
    name?: string;
    recalculation_needed?: boolean;
    source: string;
};
export namespace njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema {
    export enum grouping {
        USER = 'user',
        ACCOUNT = 'account',
        BANK = 'bank',
        INSTRUMENT = 'instrument',
    }
}

