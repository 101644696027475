import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((app) => {
  const $router = useRouter();
  const { SENTRY_DSN, SENTRY_ENV } = useRuntimeConfig().public;

  if (SENTRY_DSN) {
    Sentry.init({
      app: app.vueApp,
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV,
      trackComponents: true,
      integrations: [Sentry.browserTracingIntegration({ $router }), Sentry.replayIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],

      // Disable capturing replays for all sessions
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    });
  }

  return {
    provide: {
      sentry: Sentry,
    },
  };
});
