import { startCase } from "lodash";
import { REPORT_ID_ERRORS, ORGANIZATION_ERRORS } from "@/constants/connectApiErrors";

export const handleAccountApiError = function (error) {
  const { t: $t } = useNuxtApp().$i18n;
  let errorMsg = error;
  if (error.response?.data?.messages) {
    const { messages } = error.response.data;
    let errors = [];
    if (messages.report_id) {
      errors = [...errors, ...messages.report_id.map((errorCode) => $t(REPORT_ID_ERRORS[errorCode]))];
    }
    if (messages.organization) {
      errors = [...errors, ...messages.organization.map((errorCode) => $t(ORGANIZATION_ERRORS[errorCode]))];
    }
    if (errors.length > 0) {
      errorMsg = errors.join(". ");
    }
  }
  return errorMsg;
};

function parseErrorMessage(messages) {
  const message = Array.isArray(messages) ? messages[0] : Object.values(messages)[0];
  const text = Array.isArray(message) ? message[0] : message;
  return text;
}

export function getErrorMessage(messages) {
  const keys = [Object.keys(messages)[0]];
  let errorText = parseErrorMessage(messages);
  while (typeof errorText !== "string" && typeof errorText !== "undefined") {
    keys.push(Object.keys(errorText)[0]);
    errorText = parseErrorMessage(errorText);
  }
  const fullText = `${keys.map(startCase).join(": ")}: ${errorText}`;
  return fullText;
}
