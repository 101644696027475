/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CalcParam = {
    created_at?: string;
    id?: string;
    mapped_asset_list?: string;
    mapped_asset_list_name?: string;
    mapped_ins_type?: CalcParam.mapped_ins_type;
    /**
     * Ticker for the security. For cash positions use CASH.SEK.
     */
    mapped_ticker?: string;
    mapped_ticker_name?: string;
    mapping_type: CalcParam.mapping_type;
    parameter_type: CalcParam.parameter_type;
    priority?: CalcParam.priority;
    updated_at?: string;
    value?: number;
    /**
     * Ticker for the security. For cash positions use CASH.SEK.
     */
    value_ins?: string;
    value_ins_name?: string;
};
export namespace CalcParam {
    export enum mapped_ins_type {
        BOND = 'BOND',
        BOND_COMMON_BOND = 'BOND - COMMON_BOND',
        BOND_CORPORATE_BOND = 'BOND - CORPORATE_BOND',
        BOND_REAL_ESTATE_BOND = 'BOND - REAL_ESTATE_BOND',
        BOND_CREDIT_LINKED_BOND = 'BOND - CREDIT_LINKED_BOND',
        BOND_EQUITY_LINKED_BOND = 'BOND - EQUITY_LINKED_BOND',
        CERT = 'CERT',
        CERT_CREDIT_CERTIFICATE = 'CERT - CREDIT_CERTIFICATE',
        CERT_INDEX_CERTIFICATE = 'CERT - INDEX_CERTIFICATE',
        CERT_INTEREST_CERTIFICATE = 'CERT - INTEREST_CERTIFICATE',
        CERT_STOCK_CERTIFICATE = 'CERT - STOCK_CERTIFICATE',
        CERT_TRACKER_CERTIFICATE = 'CERT - TRACKER_CERTIFICATE',
        CERT_MARKET_CERTIFICATE = 'CERT - MARKET_CERTIFICATE',
        CDS = 'CDS',
        CURRENCY = 'CURRENCY',
        ETF = 'ETF',
        ETN = 'ETN',
        FUTURE = 'FUTURE',
        INDEX = 'INDEX',
        MFUND = 'MFUND',
        MFUND_ALTERNATIVE_INVESTMENT_FUND = 'MFUND - ALTERNATIVE_INVESTMENT_FUND',
        MFUND_CREDIT_FUND = 'MFUND - CREDIT_FUND',
        MFUND_EQUITY_FUND = 'MFUND - EQUITY_FUND',
        MFUND_HEDGE_FUND = 'MFUND - HEDGE_FUND',
        MFUND_INTEREST_RATE_FUND = 'MFUND - INTEREST_RATE_FUND',
        MFUND_INTEREST_RATE_HIGH_YIELD_FUND = 'MFUND - INTEREST_RATE_HIGH_YIELD_FUND',
        MFUND_MIXED_FUND = 'MFUND - MIXED_FUND',
        MFUND_REAL_ESTATE_FUND = 'MFUND - REAL_ESTATE_FUND',
        MFUND_OTHER_FUND = 'MFUND - OTHER_FUND',
        NOTE = 'NOTE',
        NOTE_CREDIT_NOTE = 'NOTE - CREDIT_NOTE',
        OPTION = 'OPTION',
        SERVICE = 'SERVICE',
        SERVICE_MANAGED_PORTFOLIO_SERVICE = 'SERVICE - MANAGED_PORTFOLIO_SERVICE',
        STOCK = 'STOCK',
        STOCK_COMMON_STOCK = 'STOCK - COMMON_STOCK',
        STOCK_PREFERRED_STOCK = 'STOCK - PREFERRED_STOCK',
        SP = 'SP',
        SP_AUTOCALL_SP = 'SP - AUTOCALL_SP',
        SP_CAPITAL_PROTECTED_SP = 'SP - CAPITAL_PROTECTED_SP',
        SP_SPRINTER_SP = 'SP - SPRINTER_SP',
        SP_OTHER_SP = 'SP - OTHER_SP',
        WARRANT = 'WARRANT',
        NONE = 'NONE',
    }
    export enum mapping_type {
        DEFAULT = 'default',
        TICKER = 'ticker',
        ASSET_LIST = 'asset_list',
        INSTRUMENT_TYPE = 'instrument_type',
    }
    export enum parameter_type {
        EXPECTED_RETURN = 'expected_return',
        VOLATILITY = 'volatility',
        CORRELATION_PROXY = 'correlation_proxy',
    }
    export enum priority {
        BEFORE_CALCULATION = 'before_calculation',
        AFTER_CALCULATION = 'after_calculation',
    }
}

