/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthAppSettings } from './AuthAppSettings';
import type { ModulesAppSettings } from './ModulesAppSettings';
export type njorda_bps_app_v1_schemas_settings_AppSettingsSchema = {
    app_enabled?: boolean;
    app_language?: njorda_bps_app_v1_schemas_settings_AppSettingsSchema.app_language;
    app_logo?: string;
    app_name?: string;
    auth?: AuthAppSettings;
    modules?: ModulesAppSettings;
    theme?: string;
};
export namespace njorda_bps_app_v1_schemas_settings_AppSettingsSchema {
    export enum app_language {
        EN = 'en',
        SV = 'sv',
    }
}

