import axios from "axios";

export default defineNuxtPlugin((app) => {
  const { $notifyError } = app;
  const baseUrl = useRuntimeConfig().public.njordaApiBaseUrl;

  const $njordaAdminApi = axios.create();
  $njordaAdminApi.interceptors.request.use(
    function (config) {
      config.baseURL = `${baseUrl}/admin/v1/`;
      config.withCredentials = true;
      return config;
    },
    function (e) {
      if (e.response && e.response.status === 401) {
        // TODO: Handle it
      }
      return Promise.reject(e);
    },
  );

  $njordaAdminApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response) {
        const code = parseInt(error.response.status);
        if (code === 401) {
          // app.$auth.logout(); // ToDo;
          // $redirectToLogin(); // ToDo:
          $notifyError({
            text: "Not logged in. Currently not supported",
          });
        } else if (code === 500) {
          $notifyError({
            text: "An internal server error occurred. Please try again later.",
          });
        } else {
          $notifyError({
            text: "An error occurred. Please try again later.",
          });
        }
      } else {
        $notifyError({
          text: "Network error. Please try again later.",
        });
      }
      return Promise.reject(error);
    },
  );

  return {
    provide: {
      njordaAdminApi: $njordaAdminApi,
    },
  };
});
