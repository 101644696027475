/* eslint-disable no-console */
export default defineNuxtPlugin(nuxtApp => {
  const log = (level, ...args) => {
    if (!nuxtApp.$sentry) {
      return;
    }
    switch (level) {
      case "error": {
        nuxtApp.$sentry.captureException(new Error(args[0], args));
        break;
      }
    }
  };

  nuxtApp.$addLogger(log, "sentry");
});
