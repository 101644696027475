/* eslint-disable no-console */
export default defineNuxtPlugin(nuxtApp => {
  const log = (level, ...args) => {
    switch (level) {
      case "log": {
        console.log(...args);
        break;
      }
      case "info": {
        console.info(...args);
        break;
      }
      case "warn": {
        console.warn(...args);
        break;
      }
      case "error": {
        console.error(...args);
        break;
      }
      default: {
        console.error(
          "setupConsoleLogger error: Unknown level:",
          level,
          ", msgs:",
          ...args
        );
      }
    }
  };

  nuxtApp.$addLogger(log, "console");
});
