/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Position3 } from './Position3';
export type njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema = {
    name: string;
    organization: string;
    positions?: Array<Position3>;
    share_scope?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema.share_scope;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema {
    export enum share_scope {
        USER = 'user',
        ORGANIZATION = 'organization',
    }
}

