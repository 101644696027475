import type { RouteLocation } from "vue-router";
import { ORG_PERMISSIONS_CUSTOM, customOrgPermissions } from "~/constants/orgPermissions";

export default defineNuxtPlugin(() => {
  /**
   * If customOrgPermissions doesn't have special set of permissions for given orgId
   *    - if requested permission is custom - we should not allow it
   *          (e.g. we should not allow b2b_test or svp users to see SEB_PoC)
   *    - if requested permission is not custom - we should allow it
   *          (e.g. svp can see Advisor or Client section)
   * If customOrgPermission has set of permission for given orgId
   *    - we should allow if permission is in custom set of permissions
   *    - we should deny if permission is not in custom set of permissions.
   */
  const hasPermission = ($route: RouteLocation, permission: string) => {
    const orgId = $route.params.org_id as string;
    if (!orgId) {
      return false;
    }
    if (!customOrgPermissions[orgId]) {
      if (ORG_PERMISSIONS_CUSTOM.includes(permission)) {
        return false;
      } else {
        return true;
      }
    } else {
      return customOrgPermissions[orgId].includes(permission);
    }
  };

  return {
    provide: {
      orgPermissions: {
        hasPermission,
      },
    },
  };
});
