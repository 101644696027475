/* eslint-disable */
import axios from "axios";
import type { AxiosError, AxiosRequestConfig } from "axios";

import type { OpenAPIConfig } from "@/api/njorda";
import type { ApiRequestOptions } from "@/api/njorda/core/ApiRequestOptions";
import { CancelablePromise } from "@/api/njorda/core/CancelablePromise";
import { getAxiosInstance } from "~/utils/axiosInstance";
import { BLOB_ENDPOINTS } from "~/utils/api";

type ResolveFN<T> = (value: T | PromiseLike<T>) => void;
type RejectFN = (reason?: any) => void;
type CancelFN = (cancelHandler: () => void) => void;

export const request = <AxiosResponse>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
): CancelablePromise<AxiosResponse> => {
  return new CancelablePromise((resolve: ResolveFN<any>, reject: RejectFN, onCancel: CancelFN) => {
    // Get the request URL. Depending on your needs, this might need additional processing,
    // @see ./src/templates/core/functions/getUrl.hbs
    let query = "";
    let body = options.body;
    if (typeof options.query !== "undefined" && Object.keys(options.query).length > 0) {
      query = `?${Object.keys(options.query)
        .map((key) => ((options.query ?? {})[key] ? `${key}=${(options.query ?? {})[key]}` : null))
        .filter((str) => !!str)
        .join("&")}`;
    }
    let path = options.url;
    if (options.path) {
      Object.keys(options.path).forEach((key) => {
        path = path.replace(`{${key}}`, (options.path ?? {})[key]);
      });
    }
    if (options.formData && options.mediaType === "multipart/form-data") {
      const formDataKeys = Object.keys(options.formData);
      const form = new FormData();
      formDataKeys.forEach((key) => {
        if (options.formData?.hasOwnProperty(key)) {
          form.append(key, options.formData[key]);
        }
      });
      body = form;
    }
    const url = `${config.BASE}${path}${query}`;

    // Optional: Get and link the cancelation token, so the request can be aborted.
    const controller = new AbortController();
    onCancel(() => controller.abort());

    // ToDo: take a look at https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1739
    // and implement the `isBlob` automatically after it's done
    const isBlob = BLOB_ENDPOINTS.some((endpoint) => options.method === endpoint[0] && options.url === endpoint[1]);

    // Execute the request. This is a minimal example, in real world scenarios
    // you will need to add headers, process form data, etc.
    // @see ./src/templates/core/axios/request.hbs
    getAxiosInstance()
      .request({
        url,
        data: body,
        method: options.method,
        headers: options.headers ?? {},
        signal: controller.signal,
        responseType: isBlob ? "blob" : "json",
      } as AxiosRequestConfig)
      .then((data) => {
        resolve(data?.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};
