/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema = {
    email: string;
    first_name: string;
    readonly id?: string;
    last_name: string;
    organization: string;
    password: string;
    user_type: njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema.user_type;
};
export namespace njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema {
    export enum user_type {
        ORG_ADMIN = 'org_admin',
        ORG_ADVISOR = 'org_advisor',
    }
}

