/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserDetails = {
    email?: string;
    first_name?: string;
    readonly id?: string;
    readonly last_login_at?: string;
    last_name?: string;
    password?: string;
    user_type: UserDetails.user_type;
};
export namespace UserDetails {
    export enum user_type {
        ORG_ADMIN = 'org_admin',
        ORG_ADVISOR = 'org_advisor',
    }
}

