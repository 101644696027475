import { defineStore } from "pinia";
import type { TUser } from "~/interfaces/app";

export const LOADER = {
  NJORDA: "NJORDA",
  CIRCLE: "CIRCLE",
};

type TLayoutSettings = {
  name: string;
  showLogo: boolean;
  showLogoTitle: boolean;
  fadeBackgroundImage: boolean;
  loaderImage: string;
  logoImage: string;
  logoImageVariant1: string;
  backgroundImage: string;
  showLanguageSwitcher: boolean;
  favicon: string;
  spinner: (typeof LOADER)[keyof typeof LOADER];
  show_period_picker: boolean;
  isFeedbackDialogOpen: boolean;
};

type TAppStoreState = {
  _layoutSettings: TLayoutSettings;
  _user: TUser | null;
  _drawer: boolean;
  _isMiniVariant: boolean;
};

const defaultLayoutSettings = {
  name: "Njorda B2B",
  showLogo: true,
  showLogoTitle: false,
  fadeBackgroundImage: true,
  loaderImage: "/loader.gif",
  logoImage: "/njorda_logo.svg",
  logoImageVariant1: "/njorda_logo.svg",
  backgroundImage: "/portfolio-fetcher@2x.svg",
  showLanguageSwitcher: true,
  favicon: "/favicon.png",
  spinner: LOADER.NJORDA,
  show_period_picker: true,
  isFeedbackDialogOpen: false,
};

export const useAppStore = defineStore("appStore", {
  state: () =>
    ({
      _layoutSettings: {
        ...defaultLayoutSettings,
      },
      _user: null,
      _drawer: true,
      _isMiniVariant: false,
    }) as TAppStoreState,
  getters: {
    layoutSettings: (state) => state._layoutSettings,
    drawer: (state) => state._drawer,
    user: (state) => state._user,
    isMiniVariant: (state) => state._isMiniVariant,
    isFeedbackDialogOpen: (state) => state._layoutSettings.isFeedbackDialogOpen,
  },
  actions: {
    // Mutations
    updateLayoutSettings(layoutSettings: Partial<TLayoutSettings>) {
      this._layoutSettings = {
        ...this._layoutSettings,
        ...layoutSettings,
      };
    },
    resetLayoutSettings() {
      this._layoutSettings = {
        ...defaultLayoutSettings,
      };
    },
    setUser(user: TUser | null) {
      this._user = user;
    },
    setDrawer(drawer: boolean) {
      this._drawer = drawer;
    },
    setIsMiniVariant(isMiniVariant: boolean) {
      this._isMiniVariant = isMiniVariant;
    },
    async logout() {
      const $router = useRouter();
      const { $organization } = useNuxtApp();
      const org = $organization.getCurrent();
      $router.push({
        path: "/logout",
        query: {
          org_id: org?.public_id,
        },
      });
    },
  },
});

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
type AppStoreNoSGA = ReturnType<typeof useAppStore>;
export type TAppStore = Omit<AppStoreNoSGA, keyof NullStore>;
