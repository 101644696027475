export default defineNuxtRouteMiddleware((_, __) => {
  const $router = useRouter();

  $router.onError((error, to) => {
    if (
      error.message.toLowerCase().includes("failed to fetch dynamically imported module") ||
      error.message.toLowerCase().includes("importing a module script failed")
    ) {
      window.location = to.fullPath;
    }
  });
});
