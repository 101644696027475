/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_api_v2_schemas_analytics_reports_ReportRowsExportSchema = {
    file_type: njorda_bps_api_v2_schemas_analytics_reports_ReportRowsExportSchema.file_type;
    row_ids: string;
};
export namespace njorda_bps_api_v2_schemas_analytics_reports_ReportRowsExportSchema {
    export enum file_type {
        CSV = 'csv',
        XLSX = 'xlsx',
    }
}

