/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataProviderSetting } from './DataProviderSetting';
export type njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema = {
    collect_identity?: boolean;
    data_providers?: Array<DataProviderSetting>;
    flanks_client_app?: Record<string, any>;
    name?: string;
    portfolio_builder_risks_enabled?: boolean;
    portfolio_builder_view?: njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema.portfolio_builder_view;
    public_id?: string;
    status_message?: string | null;
    tink_client_key?: Record<string, any>;
};
export namespace njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema {
    export enum portfolio_builder_view {
        NORMAL = 'normal',
        SIMPLIFIED = 'simplified',
    }
}

