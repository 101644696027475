import axios from "axios";

export default defineNuxtPlugin(() => {
  const $njordaEmbedB2BApi = axios.create();
  const baseUrl = useRuntimeConfig().public.njordaApiBaseUrl;
  const getEmbedB2BApiClient = (API_TOKEN: string, baseURI = "b2b/v1/") => {
    $njordaEmbedB2BApi.interceptors.request.use(
      function (config) {
        config.baseURL = `${baseUrl}/${baseURI}`;
        // config.withCredentials = true;
        config.headers.Authorization = `Bearer ${API_TOKEN}`;
        return config;
      },
      function (e) {
        return Promise.reject(e);
      },
    );

    $njordaEmbedB2BApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error && axios.isCancel(error)) {
          return;
        }
        return Promise.reject(error);
      },
    );

    return $njordaEmbedB2BApi;
  };

  return {
    provide: {
      getEmbedB2BApiClient,
    },
  };
});
