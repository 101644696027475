export const ORG_PERMISSIONS = {
  ADVISOR: "ADVISOR",
  MY_BENEFIT_DEMO: "MY_BENEFIT_DEMO",
  SEB_POC: "SEB_POC",
};

export const ORG_PERMISSIONS_CUSTOM = [ORG_PERMISSIONS.MY_BENEFIT_DEMO, ORG_PERMISSIONS.SEB_POC];

export const customOrgPermissions: Record<string, string[]> = {
  "my-benefit": [ORG_PERMISSIONS.MY_BENEFIT_DEMO],
  "seb-poc": [ORG_PERMISSIONS.SEB_POC],
};
