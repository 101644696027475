/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RiskFactor = {
    description?: string;
    name?: string;
    type?: RiskFactor.type;
};
export namespace RiskFactor {
    export enum type {
        SECTOR = 'sector',
        GEOGRAPHY = 'geography',
        ASSET_CLASS = 'asset class',
        CURRENCY = 'currency',
        ASSET_CLASS_NEW = 'asset class new',
        CATEGORY_GROUP = 'category group',
        CATEGORY_NAME = 'category name',
    }
}

