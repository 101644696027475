/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema = {
    report_id: string;
    scope?: Array<'identity' | 'investments' | 'loans' | 'transactions'>;
    status: njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema.status;
};
export namespace njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema {
    export enum status {
        INITIALIZED = 'initialized',
        CONSENT_RECEIVED = 'consent_received',
        BANKS_SELECTED = 'banks_selected',
        COMMITTED = 'committed',
        PROCESSING = 'processing',
        COMPLETED = 'completed',
        ERROR = 'error',
    }
}

