import type { Route } from "@sentry/vue/types/router";
import type { LocationQueryRaw } from "vue-router";
import type { IAuth } from "./njordaB2BAuth";

export default defineNuxtPlugin(() => {
  const redirectToLogin = (currentRoute?: Route) => {
    const app = useNuxtApp();
    if (!(app.$auth as IAuth).loggedIn) {
      const router = useRouter();
      const query = {} as LocationQueryRaw;
      const _route = currentRoute || useRoute();
      if (_route.path !== "/") {
        query.next = _route.path;
      }

      if (_route?.params?.org_id) {
        const path = `/advisor/${_route.params.org_id}/login`;
        if (_route.path !== path) {
          router.replace({
            path: `/advisor/${_route.params.org_id}/login`,
            query,
          });
        }
        return;
      }
      return router.replace({ path: "/login", query });
    }
  };

  return {
    provide: {
      redirectToLogin,
    },
  };
});
