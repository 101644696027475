/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Institution } from './Institution';
export type Account2 = {
    asset_type?: Account2.asset_type;
    balance?: number;
    currency: string;
    id?: string;
    institution?: Institution;
    investment_type?: Account2.investment_type;
    is_manual_account?: boolean;
    loan_type?: Account2.loan_type;
    market_value?: number;
    name: string;
    number?: string;
    type: Account2.type;
};
export namespace Account2 {
    export enum asset_type {
        HOME = 'home',
    }
    export enum investment_type {
        AF = 'af',
        ISK = 'isk',
        KF = 'kf',
        TJP = 'tjp',
        DEPOT = 'depot',
        OTHER = 'other',
    }
    export enum loan_type {
        MORTGAGE = 'mortgage',
        BLANCO = 'blanco',
        MEMBERSHIP = 'membership',
        VEHICLE = 'vehicle',
        LAND = 'land',
        STUDENT = 'student',
        CREDIT = 'credit',
        OTHER = 'other',
    }
    export enum type {
        CHECKING = 'checking',
        SAVINGS = 'savings',
        INVESTMENT = 'investment',
        CREDIT_CARD = 'credit_card',
        LOAN = 'loan',
        PENSION = 'pension',
        ASSET = 'asset',
        OTHER = 'other',
    }
}

