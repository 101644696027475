/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ExportAssetLists = {
    file_type: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ExportAssetLists.file_type;
    organization: string;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ExportAssetLists {
    export enum file_type {
        XLSX = 'xlsx',
        CSV = 'csv',
    }
}

