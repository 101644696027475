import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import { useAdvisorStore } from "./advisor";
import { usePortfolioAssetsStore } from "~/store/portfolioAssets";
import { MAPPING_TYPE_PRIORITY_RANK, EXPORT_FORMATS } from "@/constants/advisor";
import type {
  Asset,
  AssetListResult,
  CalcParam,
  njorda_bps_b2b_v1_schemas_calc_param_CreateCalcParamB2BSchema,
  njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema,
} from "~/api/njorda";
import type { TInstrumentTypes } from "~/interfaces/calcs";
import { compareStringsWithLocale } from "~/utils/compare";

type TCalcsStore = {
  _data: CalcParam[];
  _assetsLists: AssetListResult[];
  _tickers: Asset[];
  _instrumentTypes: Record<string, string | undefined> | null;
};

type TRowToCreate = njorda_bps_b2b_v1_schemas_calc_param_CreateCalcParamB2BSchema;
type TRowToUpdate = {
  id: string;
} & njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema;

export const useCalcsStore = defineStore("calcsStore", {
  state: () =>
    ({
      _data: [],
      _assetsLists: [],
      _tickers: [],
      _instrumentTypes: null,
    }) as TCalcsStore,
  getters: {
    calculationParameters: (state) => ({
      data: state._data,
      assetsLists: state._assetsLists,
      tickers: state._tickers,
      instrumentTypes: state._instrumentTypes,
    }),
  },
  actions: {
    // Mutators:
    setCalcParams(params: CalcParam[] | null) {
      if (params && params?.length > 0) {
        this._data = params.sort((a, b) => {
          // First, sort by parameter type (so all expected returns come before all volatility)
          if (a.parameter_type !== b.parameter_type) {
            if (b.parameter_type === "expected_return") {
              return 1;
            } else {
              return -1;
            }
          }

          // Then sort by priority. (all before_calulation come first)
          if (a.priority !== b.priority) {
            if (b.priority === "before_calculation") {
              return 1;
            } else {
              return -1;
            }
          }

          // Then sort by Mapping type ( in this order: ticker, asset list, instrument_type, default)
          if (a.mapping_type !== b.mapping_type) {
            return (
              (MAPPING_TYPE_PRIORITY_RANK[b.mapping_type] ?? 0) - (MAPPING_TYPE_PRIORITY_RANK[a.mapping_type] ?? 0)
            );
          }

          // Then sort by Ticker alphabetically
          if (a.mapped_ticker !== b.mapped_ticker) {
            return compareStringsWithLocale(a.mapped_ticker ?? "", b.mapped_ticker ?? "");
          }

          // Then sort by asset list alphabetically
          if (a.mapped_asset_list !== b.mapped_asset_list) {
            return compareStringsWithLocale(a.mapped_asset_list ?? "", b?.mapped_asset_list ?? "");
          }

          // Then sort by Mapped Ins Type  alphabetically
          if (a.mapped_ins_type !== b.mapped_ins_type) {
            return compareStringsWithLocale(a.mapped_ins_type ?? "", b?.mapped_ins_type ?? "");
          }

          return 0;
        });
      } else {
        this._data = [];
      }
    },
    setCalcParametersAssetsLists(payload: AssetListResult[]) {
      if (payload) {
        this._assetsLists = payload;
        this._tickers = payload.reduce((acc, obj) => acc.concat(obj.assets ?? []), [] as Asset[]);
      }
    },
    setCalcParametersInstrumentTypes(payload: TInstrumentTypes) {
      if (payload) {
        this._instrumentTypes = payload;
      }
    },

    // Actions:
    updateCalcParameters(updatesByRow: Array<TRowToUpdate | TRowToCreate>) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const { public_id } = organization;
      const updatePromises = updatesByRow.map((update) => {
        if ((update as TRowToUpdate).id) {
          const { id, ...payload } = update as TRowToUpdate;
          return DefaultService.patchB2BV1CalcParams(id, payload).catch((error) => {
            let msg;
            if (error?.response?.data?.messages) {
              msg = Object.values(error?.response?.data?.messages).join(". ");
            } else {
              msg = error;
            }
            useNuxtApp().$notifyError({
              text: `Update calc parameters. ${msg}`,
            });
            return null;
          });
        } else {
          return DefaultService.postB2BV1CalcParams(public_id, update as TRowToCreate).catch((error) => {
            let msg;
            if (error?.response?.data?.messages) {
              msg = Object.values(error?.response?.data?.messages).join(". ");
            } else {
              msg = error;
            }
            useNuxtApp().$notifyError({
              text: `Add calc parameters. ${msg}`,
            });
            return null;
          });
        }
      });
      return Promise.all(updatePromises)
        .then(async () => {
          await this.getCalcParameters();
        })
        .catch((error) =>
          useNuxtApp().$notifyError({
            text: `Error updating calc parameters: ${error}`,
          }),
        );
    },
    getCalcParameters() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const { public_id } = organization;
      return DefaultService.getB2BV1CalcParams(public_id).then(
        async (resp) => {
          await this.setCalcParams(resp?.items ?? []);
          return resp;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Get calc parameters. ${error}`,
          });
        },
      );
    },
    deleteCalcParameters(paramId: string) {
      return DefaultService.deleteB2BV1CalcParams(paramId).then(
        async () => {
          await this.getCalcParameters();
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Delete calc parameters. ${error}`,
          });
        },
      );
    },
    async loadCalcParametersData() {
      await Promise.all([
        this.getCalcParametersAssetsLists(),
        this.getCalcParametersInstrumentTypes(),
        this.getCalcParameters(),
      ]);
    },
    async getCalcParametersAssetsLists() {
      const { fetchAssets } = usePortfolioAssetsStore();
      const assetsLists = await fetchAssets();
      if (assetsLists) {
        this.setCalcParametersAssetsLists(assetsLists.filter((list: AssetListResult) => !list.is_archived));
      }
    },
    async getCalcParametersInstrumentTypes() {
      const instrumentTypes = await DefaultService.getB2BV1InstrumentTypes().then((resp) => resp || {});
      this.setCalcParametersInstrumentTypes(instrumentTypes);
    },
    importCalcParameters(file: Blob) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1CalcParamsImport(organization.public_id, {
        calc_params: file,
      });
    },
    exportCalcParameters() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1CalcParamsExport(EXPORT_FORMATS.CSV, organization.public_id);
    },
  },
});

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
type AppStoreNoSGA = ReturnType<typeof useCalcsStore>;
export type TAppStore = Omit<AppStoreNoSGA, keyof NullStore>;
