import { custom } from "@lib/utils/icons";
import { mdi } from "vuetify/iconsets/mdi";
import color from "color";

// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";

import "@/assets/style/main.scss";
import { createVuetify, type IconSet } from "vuetify";

const njordaCustomTheme = {
  dark: false,
  colors: {
    "background": "#FCF8F3",
    "divider": "#F9F1E8",
    "border": "#F7EDE2",
    "loader": "#FC4832",
    "surface": "#FFF",
    "surface-loader": "#FC4832",
    "text": "#303030",
    "text-base": "#303030",
    "anchor": "#03A48D",
    "primary": "#FC4832",
    "primary-base": "#FC4832",
    "primary-lighten-1": "#FF4F37",
    "primary-lighten-2": "#F5E2DC",
    "primary-darken-1": "#E3412D",
    "secondary": "#03A48D",
    "secondary-base": "#03A48D",
    "secondary-lighten-1": "#D8F2E5",
    "secondary-lighten-2": "#E9F1ED",
    "secondary-lighten-3": "#F4F8F6",
    "secondary-lighten-4": "#C7DDD2",
    "secondary-darken-1": "#03947F",
    "secondary-darken-2": "#058976",
    "secondary-text": "#303030",
    "error": "#FC4832",
    "error-base": "#FC4832",
    "error-lighten-1": "#FF4F37",
    "error-darken-1": "#E3412D",
    "warning-base": "#FFB93F",
    "warning-lighten-1": "#FFDD54",
    "warning-darken-1": "#F8AC39",
    "success-base": "#03A48D",

    "info": "#FC4832",
    "info-base": "#FC4832",
    "info-surface": "#F9F1E8",
    "info-surface-text": "#303030",
    "info-surface-lighten-1": "#FCF8F3",
    "info-surface-lighten-1-text": "#303030",

    "info-surface2": "#E9F1ED",
    "info-surface2-base": "#E9F1ED",
    "info-surface2-text": "#303030",
    "info-surface2-lighten-1": "#F4F8F6",
    "info-surface2-lighten-1-text": "#303030",

    "positive": "#03A48D",
    "positive-base": "#03A48D",
    "positive-surface": "#E9F1ED",
    "positive-surface-text": "#303030",
    "positive-surface-lighten-1": "#F4F8F6",
    "positive-surface-lighten-1-text": "#303030",

    "negative": "#FC4832",
    "negative-base": "#FC4832",
    "negative-surface": "#FFF5F5",
    "negative-surface-text": "#303030",

    "gray": "#9E9E9E",
    "gray-base": "#9E9E9E",
    "gray-lighten-1": "#E4E5E5",
    "gray-lighten-2": "#EAEAEA",
    "gray-lighten-3": "#B7B7B7",
    "gray-lighten-4": "#F9F9F9",
    "gray-darken-1": "#757575",

    "chart": "#03A48D",
    "chart-base": "#03A48D",
    "chart-accent-1": "#55bf8f",
    "chart-accent-2": "#8cd891",
    "chart-accent-3": "#c4f093",
    "chart-accent-4": "#FFC500",
    "chart-accent-5": "#FFB400",
    "chart-accent-6": "#F8A000",
    "chart-accent-7": "#F03529",
    "chart-accent-8": "#D81A1A",
    "chart-accent-9": "#BB000C",
  },
};

const themes = {
  AVENIR_INVEST: {
    background: "#253745",
    primary: "#E0D9CF",
    secondary: "#C27A62",
  },
  MAX_MATTHIESSEN: {
    "background": "#133A74",
    "anchor": "#133A74",
    "primary": "#30497B",
    "secondary": "#133A74",
    "info-surface": "#D7D7D7",
    "info-surface-lighten-1": "#EAEAEA",
    "info-surface2": "#D7D7D7",
    "info-surface2-lighten-1": "#EAEAEA",
  },
  KNOX_CAPITAL: {
    background: "#132038",
    primary: "#132038",
    secondary: "#748EBE",
  },
  SVP: {
    background: "#FFFFFF",
    primary: "#918271",
    secondary: "#726659",
  },
  NJORDA_MOCK_WHITE: {
    background: "#A8A8A8",
    anchor: "#fff",
    primary: "#EAEAEA",
    secondary: "#A8A8A8",
  },
  MYBENEFIT: {
    background: "#fff",
    primary: "#303030",
    secondary: "#303030",
  },
  SEB_POC: {
    "background": "#eee",
    "primary": "#333",
    "secondary": "#868686",
    "success": "#308800",
    "anchor": "#308800",
    "breadcrumb": "#0062BC",
    "chart": "#308800",
    "chart-base": "#308800",
    "chart-accent-1": "#45B400",
    "chart-accent-2": "#60CD18",
  },
};

const vuetifyDefaulColorStructure = ["primary", "secondary"];

const brandThemes = {};
Object.keys(themes).forEach((key) => {
  const theme = themes[key];
  const outputTheme = {
    dark: false,
    colors: {},
  };

  Object.keys(theme).forEach((colorKey) => {
    const colors = {
      [colorKey]: theme[colorKey],
    };
    if (vuetifyDefaulColorStructure.includes(colorKey)) {
      colors[`${colorKey}-base`] = theme[colorKey];

      const baseColor = color(theme[colorKey]);
      const variants: Record<string, string> = {
        "lighten-1": baseColor.lightness(76).hex(),
        "lighten-2": baseColor.lightness(86).hex(),
        "lighten-3": baseColor.lightness(96).hex(),
        "darken-1": baseColor.blacken(0.5).hex(),
        "darken-2": baseColor.blacken(0.6).hex(),
        "darken-3": baseColor.blacken(0.7).hex(),
      };
      Object.keys(variants).forEach((key) => {
        colors[`${colorKey}-${key}`] = variants[key];
      });
    }

    outputTheme.colors = {
      ...outputTheme.colors,
      ...colors,
    };

    outputTheme.colors["surface-loader"] = outputTheme.colors["secondary-base"];
    outputTheme.colors["info-surface"] = outputTheme.colors["info-surface"]
      ? outputTheme.colors["info-surface"]
      : outputTheme.colors["secondary-lighten-2"];
    outputTheme.colors["info-surface-lighten-1"] = outputTheme.colors["info-surface-lighten-1"]
      ? outputTheme.colors["info-surface-lighten-1"]
      : outputTheme.colors["secondary-lighten-3"];
    outputTheme.colors["info-surface2"] = outputTheme.colors["info-surface2"]
      ? outputTheme.colors["info-surface2"]
      : outputTheme.colors["secondary-lighten-2"];
    outputTheme.colors["info-surface2-lighten-1"] = outputTheme.colors["info-surface2-lighten-1"]
      ? outputTheme.colors["info-surface2-lighten-1"]
      : outputTheme.colors["secondary-lighten-3"];
    outputTheme.colors.divider = outputTheme.colors["secondary-base"];
    outputTheme.colors.border = outputTheme.colors["secondary-base"];

    if (outputTheme.colors.background === outputTheme.colors["secondary-base"]) {
      // Loader color will take up the default surface/card colour on no-card surface when secondary and background are the same.
      outputTheme.colors.loader = "#fff";
    } else {
      outputTheme.colors.loader = outputTheme.colors["secondary-base"];
    }
  });
  brandThemes[key] = {
    ...outputTheme,
    colors: {
      ...njordaCustomTheme.colors,
      ...outputTheme.colors,
    },
  };
});

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      defaultTheme: "njordaCustomTheme",
      themes: {
        njordaCustomTheme,
        ...brandThemes,
      },
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        n: custom as IconSet,
      },
    },
    display: {
      mobileBreakpoint: "sm",
      thresholds: {
        xs: 0, // < 576px
        sm: 576, // 576px > x > 768px
        md: 768, // 768px > x > 992px
        lg: 992, // 992px > x > 1200px
        xl: 1200, // 1200px > x > 1400px
        xxl: 1400, // 1400px <
      },
    },
  });
  app.vueApp.use(vuetify);
});
