/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HoldingsReportSettings = {
    delete_report_after?: number;
    enable_manual_accounts?: boolean;
    hide_error_details?: boolean;
    redirect_on_connect?: boolean;
    report_data?: boolean;
    report_view?: HoldingsReportSettings.report_view;
};
export namespace HoldingsReportSettings {
    export enum report_view {
        SIMPLE = 'simple',
        DETAILED_VIEW = 'detailed_view',
        ALLOCATIONS_AND_POSITIONS = 'allocations_and_positions',
    }
}

