import axios from "axios";
import type { AxiosInstance } from "axios";

let axiosInstance = axios.create();

export const getAxiosInstance = () => axiosInstance;
export const setAxiosInstance = (instance: AxiosInstance) => {
  if (isAxios(instance)) {
    axiosInstance = instance;
  } else {
    throw new TypeError("WRONG CLIENT");
  }
};

function isAxios(client: AxiosInstance) {
  return client.interceptors && !!client.get && !!client.post && client.delete;
}
