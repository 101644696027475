import { useAdvisorStore } from "@/store/advisor";
import type { Router } from "vue-router";
import type { TOrganization } from "~/interfaces/app";

export default defineNuxtPlugin((app) => {
  const getCurrentOrganization = () => {
    const $route = (app.$router as Router).currentRoute.value;
    const { organizations, organization } = useAdvisorStore();
    if (organization) {
      return organization;
    }
    if (!organizations || organizations.length < 1) {
      return null;
    }
    const routeOrgId = $route.params.org_id;
    let _organization = organizations.find((org) => org.public_id === routeOrgId);
    if (routeOrgId && _organization) {
      setCurrentOrganization(_organization);
      return _organization;
    }

    const orgId = localStorage.getItem("advisor/lastOrgId");
    _organization = organizations.find((org) => org.public_id === orgId);
    if (orgId && _organization) {
      setCurrentOrganization(_organization);
      return _organization;
    }
    setCurrentOrganization(organizations[0]);
    return organizations[0];
  };

  const setCurrentOrganization = (organization: TOrganization | null) => {
    if (!organization) {
      localStorage.removeItem("advisor/lastOrgId");
      return;
    }
    const orgId = organization.public_id;
    const { organizations, setSelectedOrganization } = useAdvisorStore();
    const isOrganizationInList = organizations.find((org) => org.public_id === orgId);
    if (isOrganizationInList) {
      localStorage.setItem("advisor/lastOrgId", orgId ?? "");
      setSelectedOrganization(organization);
    }
  };

  return {
    provide: {
      organization: {
        getCurrent: getCurrentOrganization,
        setCurrent: setCurrentOrganization,
      },
    },
  };
});
