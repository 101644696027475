import { defineStore } from "pinia";
import type { TUser } from "~/interfaces/app";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import { useAdvisorStore } from "./advisor";
import type {
  njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema,
  njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema,
  CancelablePromise,
} from "~/api/njorda";

type TUserStore = {
  _users: TUser[] | null;
};

export const useUserStore = defineStore("userStore", {
  state: () =>
    ({
      _users: null,
    }) as TUserStore,
  getters: {
    users: (state) => state._users,
  },
  actions: {
    // Mutations
    setUsers(users: TUser[] | null) {
      this._users = users;
    },

    // Actions
    fetchUsers() {
      this.setUsers(null);
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.getB2BV1AdvisorUsers(organization.public_id).then(
        (resp) => {
          this.setUsers(resp?.items ?? null);
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Fetch users error: ${error}`,
          });
        },
      );
    },
    deleteUsers(userIds: string[]) {
      const requests = <CancelablePromise<void>[]>[];
      userIds.forEach((id) => {
        requests.push(DefaultService.deleteB2BV1AdvisorUsers(id));
      });
      return Promise.all(requests)
        .then(async () => {
          await this.fetchUsers();
        })
        .catch((error) => {
          useNuxtApp().$notifyError({
            text: `Error deleting multiple users. ${error}`,
          });
        });
    },
    addUser(payload: Omit<njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema, "organization">) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1AdvisorUsers({
        ...payload,
        organization: organization.public_id,
      })
        .then(async (usr) => {
          await this.fetchUsers();
          return usr;
        })
        .catch((error) => {
          useNuxtApp().$notifyError({
            text: `Adding user error: ${error?.message ?? error}`,
          });
          return Promise.reject(error);
        });
    },
    updateUser(user: { id: string; data: njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema }) {
      return DefaultService.patchB2BV1AdvisorUsers(user.id, user.data)
        .then(async (resp) => {
          await this.fetchUsers();
          return resp;
        })
        .catch((error) => {
          useNuxtApp().$notifyError({
            text: `Update user. ${error}`,
          });
          return Promise.reject(error);
        });
    },
  },
});

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
type AppStoreNoSGA = ReturnType<typeof useUserStore>;
export type TAppStore = Omit<AppStoreNoSGA, keyof NullStore>;
