import { DefaultService } from "@/api/njorda/services/DefaultService";
import { OpenAPI } from "@/api/njorda/core/OpenAPI";
import { useAppStore, type TAppStore } from "~/store/app";
import type { TUser } from "~/interfaces/app";

export interface IAuth {
  constructor: Function; // eslint-disable-line @typescript-eslint/ban-types
  user: TUser | null;
  loggedIn: boolean;
  init: Function; // eslint-disable-line @typescript-eslint/ban-types
  fetchUser: Function; // eslint-disable-line @typescript-eslint/ban-types
  login: Function; // eslint-disable-line @typescript-eslint/ban-types
  logout: Function; // eslint-disable-line @typescript-eslint/ban-types
}

/*
  Please note notifyError plugin is not accessible here so the new setup
  for error notifications is created here.
*/
class Auth implements IAuth {
  private $store: TAppStore;

  constructor() {
    this.$store = useAppStore();
    OpenAPI.WITH_CREDENTIALS = true;
  }

  get user() {
    return this.$store.user;
  }

  get loggedIn() {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    try {
      return JSON.parse(isLoggedIn ?? "") === true;
    } catch (e) {
      return false;
    }
  }

  async init() {
    return await this.fetchUser().catch(() => {});
  }

  fetchUser() {
    return DefaultService.getB2BV1AuthUser()
      .then(async (user: TUser) => {
        if (user) {
          await this.$store.setUser(user);
          return Promise.resolve(user);
        } else {
          return Promise.reject(new Error("Invalid user returned"));
        }
      })
      .catch(async (e) => {
        await this.$store.setUser(null);
        return Promise.reject(e);
      });
  }

  logout(justClearState = false) {
    const clearState = async () => {
      localStorage.removeItem("isLoggedIn");
      await this.$store.setUser(null);
    };
    if (justClearState) {
      return clearState();
    }
    return DefaultService.postB2BV1AuthLogout()
      .catch(() => {})
      .finally(() => {
        clearState();
      });
  }

  login(email: string, password: string, organization: string) {
    return DefaultService.postB2BV1AuthLogin({
      email,
      password,
      organization,
    }).then((token) => {
      OpenAPI.TOKEN = token?.token;
      localStorage.setItem("isLoggedIn", "true");
      return this.fetchUser();
    });
  }
}

export default defineNuxtPlugin(() => {
  const $auth = new Auth();

  return {
    provide: {
      auth: $auth,
    },
  };
});
