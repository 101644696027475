import { useAdvisorStore } from "@/store/advisor";

export default defineNuxtRouteMiddleware(async (to) => {
  const $router = useRouter();
  const { $auth, $organization } = useNuxtApp();

  if (to.path.startsWith("/clients")) {
    return $router.replace({ path: "/advisor" });
  }
  if ($auth.loggedIn && to.params.org_id) {
    let orgs = useAdvisorStore().organizations;
    if (!orgs || !orgs.length) {
      await useAdvisorStore().fetchOrganizations();
    }
    orgs = useAdvisorStore().organizations;
    const org = orgs.find((org) => org.public_id === to.params.org_id);
    if (!org) {
      $organization.setCurrent(null);
      return $router.replace({ path: "/advisor" });
    }
    if (to.params.org_id !== useAdvisorStore().organization?.public_id) {
      $organization.setCurrent(org);
    }
  }
});
