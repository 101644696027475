/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterItem } from './FilterItem';
export type njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema = {
    columns: Array<string>;
    filters?: Array<FilterItem>;
    grouping: njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema.grouping;
    name?: string;
    original_report_id?: string;
    recalculation_needed?: boolean;
    source: string;
};
export namespace njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema {
    export enum grouping {
        USER = 'user',
        ACCOUNT = 'account',
        BANK = 'bank',
        INSTRUMENT = 'instrument',
    }
}

