import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import type {
  njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema,
  njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema,
} from "~/api/njorda";
import { useAdvisorStore } from "./advisor";

export const usePortfolioBenchmarksStore = defineStore("portfolioBenchmarksStore", {
  actions: {
    fetchBenchmarks() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.getB2BV1PortfolioBuilderBenchmarkPortfolios(organization?.public_id).then(
        (resp) => resp?.items,
      );
    },
    fetchBenchmarkItems(benchmarkId: string) {
      return DefaultService.getB2BV1PortfolioBuilderBenchmarkPortfolios1(benchmarkId);
    },
    createNewBenchmark(
      benchmark: Omit<
        njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema,
        "organization"
      >,
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const body = {
        ...benchmark,
        organization: organization?.public_id,
      } as njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema;
      return DefaultService.postB2BV1PortfolioBuilderBenchmarkPortfolios(body);
    },
    updateBenchmark({
      benchmarkId,
      benchmark,
    }: {
      benchmarkId: string;
      benchmark: njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema;
    }) {
      return DefaultService.patchB2BV1PortfolioBuilderBenchmarkPortfolios(benchmarkId, benchmark);
    },
    deleteBenchmark(benchmarkId: string) {
      return DefaultService.deleteB2BV1PortfolioBuilderBenchmarkPortfolios(benchmarkId);
    },
  },
});
