export default defineNuxtPlugin(() => {
  const { $toast } = useNuxtApp();

  const notifyError = (data: Record<string, string>) => {
    $toast.error(data.text, { ...data });
  };

  const notifySuccess = (data: Record<string, string>) => {
    $toast.success(data.text, { ...data });
  };

  const notifyInfo = (data: Record<string, string>) => {
    $toast.info(data.text, { ...data });
  };

  const clearNotifications = () => {
    $toast.clear();
  };

  return {
    provide: {
      notifyError,
      notifySuccess,
      notifyInfo,
      clearNotifications,
    },
  };
});
