/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Transaction = {
    amount: number;
    currency: Transaction.currency;
    date: string;
    description?: string;
    fee?: number;
    fx_rate?: number | null;
    id?: string | null;
    /**
     * Ticker for the security. For cash positions use CASH.SEK.
     */
    instrument: string;
    quantity: number;
    type: Transaction.type;
};
export namespace Transaction {
    export enum currency {
        USD = 'USD',
        EUR = 'EUR',
        SEK = 'SEK',
        BRL = 'BRL',
        GBP = 'GBP',
        GBX = 'GBX',
    }
    export enum type {
        TRADE = 'TRADE',
        TRANSFER = 'TRANSFER',
        POS_ADJUST = 'POS_ADJUST',
        FEE = 'FEE',
        FEE_REFUND = 'FEE_REFUND',
        DIVIDEND = 'DIVIDEND',
        INTEREST = 'INTEREST',
        OTHER = 'OTHER',
        DEPOSIT = 'DEPOSIT',
        WITHDRAWAL = 'WITHDRAWAL',
        TAX = 'TAX',
        CANCEL = 'CANCEL',
        RECEIPT = 'RECEIPT',
        INCOME = 'INCOME',
        DEBIT = 'DEBIT',
        FOREX = 'FOREX',
        ACCOUNT_OPENING = 'ACCOUNT_OPENING',
        ACCOUNT_CLOSURE = 'ACCOUNT_CLOSURE',
        CREDIT = 'CREDIT',
        INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
        CREDIT_CARD = 'CREDIT_CARD',
        PAYMENT = 'PAYMENT',
        DEFAULT = 'DEFAULT',
    }
}

