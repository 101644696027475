/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_api_v2_schemas_analytics_reports_ReportExportSchema = {
    file_type: njorda_bps_api_v2_schemas_analytics_reports_ReportExportSchema.file_type;
};
export namespace njorda_bps_api_v2_schemas_analytics_reports_ReportExportSchema {
    export enum file_type {
        CSV = 'csv',
        XLSX = 'xlsx',
    }
}

