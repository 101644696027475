import { USER_PERMISSIONS } from "~/constants/advisor";
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";

export default defineNuxtPlugin(() => {
  const { user } = storeToRefs(useAppStore());
  const hasPermission = (permission: string) => {
    const userRole = user.value?.user_type;
    if (!userRole) {
      return false;
    }
    return (USER_PERMISSIONS[permission] || []).includes(userRole);
  };

  return {
    provide: {
      userRole: {
        hasPermission,
      },
    },
  };
});
