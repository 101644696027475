/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalExportSchema = {
    file_type: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalExportSchema.file_type;
    proposal_ids: string;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalExportSchema {
    export enum file_type {
        PDF = 'pdf',
        XLSX = 'xlsx',
    }
}

