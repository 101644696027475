/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ListAssetListsArgsSchema = {
    include_archived?: boolean;
    organization: string;
    type?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ListAssetListsArgsSchema.type;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ListAssetListsArgsSchema {
    export enum type {
        PORTFOLIO_BUILDER = 'portfolio_builder',
        ASSET_CLASS = 'asset_class',
    }
}

