<template>
  <svg width="24" height="24" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g class="spinner-animation">
      <circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle>
    </g>
  </svg>
</template>

<style lang="scss">
  .spinner-animation {
    transform-origin: center;
    animation: spinner_animation_outer 2s linear infinite;

    circle {
      stroke-linecap: round;
      animation: spinner_animation_inner 1.5s ease-in-out infinite;
    }
  }
  
  @keyframes spinner_animation_outer {
    100% {
      transform: rotate(360deg)
    }
  }
  
  @keyframes spinner_animation_inner {
    0% {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0
    }
    47.5% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -16
    }
    95%,100% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -59
    }
  }
</style>