/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Asset } from './Asset';
export type njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema = {
    assets?: Array<Asset>;
    name: string;
    organization: string;
    type?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema.type;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema {
    export enum type {
        PORTFOLIO_BUILDER = 'portfolio_builder',
        ASSET_CLASS = 'asset_class',
    }
}

