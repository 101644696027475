/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ColumnOption = {
    default?: boolean;
    deletable?: boolean;
    id?: string;
    label?: string;
    type?: ColumnOption.type;
};
export namespace ColumnOption {
    export enum type {
        STRING = 'String',
        PRICE = 'Price',
        PERCENTAGE = 'Percentage',
        NUMBER = 'Number',
    }
}

