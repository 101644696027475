import {
  type njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema,
  type njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema,
  DefaultService,
  type PortfolioTemplateResult,
  type Position3,
} from "~/api/njorda";
import { useAdvisorStore } from "./advisor";

type TPortfolioTemplatesStore = {
  _templates: PortfolioTemplateResult[];
  _templatesInProgress: boolean;
};

export const usePortfolioTemplatesStore = defineStore("portfolioTemplatesStore", {
  state: () =>
    ({
      _templates: [],
      _templatesInProgress: false,
    }) as TPortfolioTemplatesStore,
  getters: {
    portfolioTemplates: (state: TPortfolioTemplatesStore) => state._templates,
    portfolioTemplatesInProgress: (state: TPortfolioTemplatesStore) => state._templatesInProgress,
  },
  actions: {
    setPortfolioTemplates(payload: PortfolioTemplateResult[]) {
      this._templates = payload;
    },
    fetchPortfolioTemplates() {
      this.setPortfolioTemplates([]);
      this._templatesInProgress = true;
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.getB2BV1PortfolioBuilderDataTemplates(organization.public_id)
        .then((resp) => {
          this.setPortfolioTemplates(resp?.items || []);
          return resp;
        })
        .finally(() => {
          this._templatesInProgress = false;
        });
    },
    deletePortfolioTemplate(portfolioId: string) {
      return DefaultService.deleteB2BV1PortfolioBuilderDataTemplates(portfolioId).then(async () => {
        await this.fetchPortfolioTemplates();
        return true;
      });
    },
    createPortfolioTemplate(
      portfolioTemplate: Omit<
        njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema,
        "organization"
      >,
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      this._templatesInProgress = true;
      return DefaultService.postB2BV1PortfolioBuilderDataTemplates({
        ...portfolioTemplate,
        organization: organization.public_id,
      })
        .then(async (res) => {
          await this.fetchPortfolioTemplates();
          return res;
        })
        .finally(() => {
          this._templatesInProgress = false;
        });
    },
    updatePortfolioTemplate(
      portfolioTemplate: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema & {
        id: string;
      },
    ) {
      const { id, ...payload } = portfolioTemplate;
      this._templatesInProgress = true;
      return DefaultService.patchB2BV1PortfolioBuilderDataTemplates(id, {
        ...payload,
        positions: payload.positions?.map((position: Position3) => ({
          allocation: position.allocation,
          ticker: position.ticker,
        })),
      })
        .then(async (res) => {
          await this.fetchPortfolioTemplates();
          return res;
        })
        .finally(() => {
          this._templatesInProgress = false;
        });
    },
  },
});
