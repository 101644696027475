/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema = {
    accumulated_capital?: number;
    age?: number;
    estimation_option?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.estimation_option;
    fee?: number;
    fixed_fee?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.fixed_fee;
    life_annuity?: number;
    monthly_salary: number;
    payout_time?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.payout_time;
    pension_age?: number;
    pension_type?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.pension_type;
    return_after_pension?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.return_after_pension;
    return_before_pension?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema.return_before_pension;
    started_working_age?: number;
};
export namespace njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema {
    export enum estimation_option {
        SELF_INPUT = 'self_input',
        ESTIMATION = 'estimation',
    }
    export enum fixed_fee {
        '_48' = 48,
        '_65' = 65,
        '_300' = 300,
        '_600' = 600,
    }
    export enum payout_time {
        '_10' = 10,
        '_11' = 11,
        '_12' = 12,
        '_13' = 13,
        '_14' = 14,
        '_15' = 15,
        '_16' = 16,
        '_17' = 17,
        '_18' = 18,
        '_19' = 19,
        '_20' = 20,
        '_23.5833' = 23.5833,
    }
    export enum pension_type {
        KAP_KL = 'kap-kl',
        AKAP_KR = 'akap-kr',
    }
    export enum return_after_pension {
        '_0' = 0,
        '_0.01' = 0.01,
        '_0.02' = 0.02,
        '_0.03' = 0.03,
        '_0.04' = 0.04,
        '_0.05' = 0.05,
        '_0.06' = 0.06,
        '_0.07' = 0.07,
        '_0.08' = 0.08,
        '_0.09' = 0.09,
        '_0.019' = 0.019,
    }
    export enum return_before_pension {
        '_0' = 0,
        '_0.01' = 0.01,
        '_0.02' = 0.02,
        '_0.03' = 0.03,
        '_0.04' = 0.04,
        '_0.05' = 0.05,
        '_0.06' = 0.06,
        '_0.07' = 0.07,
        '_0.08' = 0.08,
        '_0.09' = 0.09,
        '_0.019' = 0.019,
    }
}

