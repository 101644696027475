<template>
  <v-app>
    <NuxtLayout layout="default">
      <v-container fluid class="align-center">
        <v-card v-if="is404Error" class="mx-auto" color="transparent" flat>
          <v-card-text class="pb-0">
            <p class="text-h1 secondary--text font-weight-bold text-center">404</p>
            <p class="body-1 secondary--text text-center">
              {{ $t("notFound.pageNotFoundSubTitle") }}
            </p>
            <p class="text-center">
              {{ $t("notFound.pageNotFoundDescription") }}
            </p>
          </v-card-text>
          <v-card-actions v-if="!isBackToMainPageHidden">
            <v-btn variant="outlined" to="/" class="mx-auto text-uppercase" color="secondary">
              {{ $t("notFound.backToMainPage") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else class="mx-auto" max-width="450" color="transparent" flat>
          <v-card-text class="pb-0">
            <p class="text-h3 text--primary font-weight-medium text-center">
              {{ otherError }}
            </p>
            <p class="body-1 text--teal text-center">
              {{ $t("notFound.errorInformation") }}
            </p>
            <p>{{ error }}</p>
          </v-card-text>
          <v-card-actions v-if="!isBackToMainPageHidden">
            <v-btn variant="outlined" class="mx-auto text-uppercase" color="teal accent-4" to="/">
              {{ $t("notFound.backToMainPage") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </NuxtLayout>
  </v-app>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
const {
  advisor: { hideBackToMainPageButtonOnErrorPage },
} = useRuntimeConfig().public;

const $route = useRoute();
const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: () => null,
  },
});
const pageNotFound = "404 Not Found";
const otherError = "An error occurred";
const is404Error = computed(() => {
  return props.error?.statusCode === 404;
});
const isEmbed = computed(() => {
  return $route.fullPath.startsWith("/embed");
});
const isBackToMainPageHidden = computed(() => {
  return isEmbed.value || hideBackToMainPageButtonOnErrorPage;
});

useHead({
  titleTemplate: `${is404Error.value ? pageNotFound : otherError} - Njorda B2B`,
});
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
