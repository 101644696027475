import { type LocaleObject } from "@nuxtjs/i18n";

export default defineNuxtPlugin(({ $i18n }) => {
  const availableLocales = $i18n.locales;
  const activeLocale = $i18n.locale;

  function setAppLocale(payload: LocaleObject) {
    if (payload) {
      $i18n.setLocale(payload.code);
    }
  }

  function getAppLocale(_activeLocale = activeLocale.value.code) {
    return availableLocales.value.find((locale: LocaleObject) => locale.code === _activeLocale);
  }

  return {
    provide: {
      availableLocales,
      activeLocale,
      setAppLocale,
      getAppLocale,
    },
  };
});
